// const URL_DATA_PROCESSOR = "http://127.0.0.1:3002";
const URL_DATA_PROCESSOR = "https://artpro.studio:3006";

export const URL_DATA_PROCESSOR_CLIENTS = `${URL_DATA_PROCESSOR}/v1.0/clients/`;
export const URL_DATA_PROCESSOR_CLIENTS_LOGIN_YANDEX = `${URL_DATA_PROCESSOR}/v1.0/clients/authentication/oauth2/login_yandex`;
export const URL_DATA_PROCESSOR_CLIENTS_LOGIN_VK = `${URL_DATA_PROCESSOR}/v1.0/clients/authentication/oauth2/login_vk`;
export const URL_DATA_PROCESSOR_CLIENTS_LOGIN_VK2 = `${URL_DATA_PROCESSOR}/v1.0/clients/authentication/oauth2/login_vk2`;
export const URL_DATA_PROCESSOR_CLIENTS_ADD_VK_ID = `${URL_DATA_PROCESSOR}/v1.0/clients/authentication/oauth2/add_vk_id`;
export const URL_DATA_PROCESSOR_CLIENTS_ADD_YANDEX_ID = `${URL_DATA_PROCESSOR}/v1.0/clients/authentication/oauth2/add_yandex_id`;

// const URL_YANDEX_OAUTH2_RESPONSE = "http%3A%2F%2F127.0.0.1%3A8080%2Fprofile%2F%3Fprovider%3DYandex";
const URL_YANDEX_OAUTH2_RESPONSE = "https%3A%2F%2Fartpro.studio%2Fprofile%2F%3Fprovider%3DYandex";

export const URL_YANDEX_OAUTH2_LOGIN = `https://oauth.yandex.ru/authorize?client_id=0a428857350c4294ae7b5808e448f471&redirect_uri=${URL_YANDEX_OAUTH2_RESPONSE}&response_type=token&state=`;

// const URL_VK_OAUTH2_RESPONSE = "http://127.0.0.1:8080/profile/";
const URL_VK_OAUTH2_RESPONSE = "https://artpro.studio/profile/";

const VK_ACCESS_SCOPE = 1024 + 4194304;
export const URL_VK_OAUTH2_LOGIN = `https://oauth.vk.com/authorize?client_id=51590178&redirect_uri=${URL_VK_OAUTH2_RESPONSE}&display=page&scope=${VK_ACCESS_SCOPE}`;

export const DAYS_TOKEN_REMEMBERING = 30;

export const URL_LOGOUT = "/profile/?logout=true";

export const URL_DATA_PROCESSOR_CLIENTS_US = `${URL_DATA_PROCESSOR}/v1.0/clients/us/`;
export const URL_DATA_PROCESSOR_CLIENTS_OUR_ACCOUNT_RELATIVE = "/v1.0/clients/our_account/";
export const URL_DATA_PROCESSOR_CLIENTS_OUR_ACCOUNT = `${URL_DATA_PROCESSOR}${URL_DATA_PROCESSOR_CLIENTS_OUR_ACCOUNT_RELATIVE}`;

export const URL_DATA_PROCESSOR_COLLECTIONS = `${URL_DATA_PROCESSOR}/v1.0/collections/`;

export const URL_DATA_PROCESSOR_IMAGES = `${URL_DATA_PROCESSOR}/v1.0/images/`;

export const URL_DATA_PROCESSOR_COMMENTARIES = `${URL_DATA_PROCESSOR}/v1.0/commentaries/`;

export const URL_DATA_PROCESSOR_VOTES = `${URL_DATA_PROCESSOR}/v1.0/votes/`;

export const MIN_IMAGE_SIZE = 720;
export const MAX_IMAGE_SIZE = 1280;

export const MIN_IMAGE_FILE_SIZE = "100 КБ";
export const MAX_IMAGE_FILE_SIZE = "1 МБ";

export class DataProcessorPaths {
	static getImageUploadingPath (inputId: string): string {
		return `${URL_DATA_PROCESSOR}/v1.0/images/${inputId}/file/`;
	}
}

export const EEntityStatus = {
	Active : 1,
	Suspended : 2,
	Blocked : 3
};

export const EEntityType = {
	Image : 1,
	Commentary : 2
};

export interface IMeta {
    total: number;
    amountPerPage: number;
    limit: number;
    page: number;
}
