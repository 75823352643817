<template>
  <div class="home">
    <HelloWorld msg="Добро пожаловать на сайт фото-фитнес-студии ArtPro!"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

@Options({
  components: {
    HelloWorld
  }
})
export default class Home extends Vue {}
</script>
