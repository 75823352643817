<template>
  <div class="hello" v-if="showCaption">
    <h2 id="h2Caption" v-if="!all">Галерея {{$route.params.id}}</h2>
    <h2 id="h2Caption" v-if="all">Изображения</h2>
  </div>

  <p v-if="message">{{message}}</p>

  <div id="divGalleriesControls" v-if="!all">
    <label for="edName">Название (2 - 128 символов): </label>
    <input class="InputProperty" type="text" id="edName" name="edName" required minlength="2" maxlength="128"/> 
    <input class="btWorkWithGallery" type="button" value="Обновить" v-on:click="btUpdateGallery_OnClick"/>
    <input class="btWorkWithGallery" type="button" value="Удалить" v-on:click="btDeleteGallery_OnClick"/>
  </div>

  <div class="MarginTopSmall" id="divImagesControls" v-if="!all">
    <h3 class="MarginTopMedium">Изображения</h3>

    <div class="MarginTopUltraSmall"><label for="edImageName">Название (2 - 128 символов): </label>
    <input type="text" class="InputProperty" id="edImageName" name="edName" required minlength="2" maxlength="128"/></div>
    <div class="MarginTopUltraSmall"><label for="edImageDescription">Описание (32 - 1024 символа): </label>
    <input type="text" class="InputProperty" id="edImageDescription" name="edImageDescription" required minlength="32" maxlength="1024"/></div>
    <div class="MarginTopUltraSmall"><label for="edImageLocation">Локация (2 - 512 символа): </label>
    <input type="text" class="InputProperty" id="edImageLocation" name="edImageLocation" required minlength="2" maxlength="512"/></div>
    <div class="MarginTopExtraSmall"><input type="button" value="Добавить" v-on:click="btAddImage_OnClick"/></div>
  </div>

  <div class="MarginTopMedium rowHorizontal" id="divImages">
    
  </div>

  <div class="MarginTopMedium" id="divSortControl">
    Сортировать по : 
    <select id="selectSort" v-on:change="selectSort_OnChange">
      <option value="1">Дате</option>
      <option value="2">Рейтингу</option>
    </select>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import {ClientsService} from '@/system/services/ClientsService';

import {
  ICollection,
  CollectionsService
} from '@/system/services/CollectionsService';

import {
  IImage,
  ImagesService
} from '@/system/services/ImagesService';

import {LocalStorage} from '@/system/common_code/Utilities';

@Options({
  props: {
    id: String,
    all: Boolean,
    limit: {
      type: Number,
      default: 24
    },
    sort: {
      type: String,
      default: ""
    },
    showCaption: Boolean,
    message: String
  },

  async created () {
    console.log (this.showCaption);

    if (this.all) {
      LocalStorage.setValue ("idGallery", "");

    } else {
      LocalStorage.setValue ("idGallery", this.$route.params.id);
    }

    await this.update ();
    await this.updateImages ();
  },

  methods: {
    async update () {
      if (this.all) {
        return;
      }

      const gallery = await CollectionsService.get (this.$route.params.id);
      console.log (gallery);
      const h2Caption = document.getElementById ("h2Caption");
      const edName = document.getElementById ("edName") as HTMLInputElement;
      if (gallery && h2Caption) {
        document.title = `Галерея ${gallery.name} - Фото-Фитнес-Студия ArtPro`;
        h2Caption.innerHTML = `Галерея ${gallery.name}`;
        edName.value = gallery.name;
      }
    },
    async updateImages () {
      const isLoggedIn = ClientsService.isLoggedIn ();

      // console.log (`Is client logged in is : ${isLoggedIn}.`);
      if (isLoggedIn || this.all) {
        await this.showClientImages ();
      }
    },
    async showClientImages () {
      const selectSort = document.getElementById ("selectSort") as HTMLInputElement;
      if (selectSort) {
        const sort = selectSort.value == "1" ? undefined : "rating";
        const images = await ImagesService.getImages (
          this.$route.params.id,
          this.limit,
          this.all,
          sort
        );
        if (images) {
          this.showReceivedImages (images);
        }
      }
    },

    btUpdateGallery_OnClick: async function () {
      const edName = document.getElementById ("edName") as HTMLInputElement;
      if (edName) {
        console.log ("Galleriy.btUpdateGallery_OnClick () : Function has been called.");
        await CollectionsService.update (this.$route.params.id, edName.value);
        // window.location.href = `/galleries/${this.$route.params.id}`;
        this.update ();
      }
    },
    btDeleteGallery_OnClick: async function () {
      console.log ("Galleriy.btDeleteGallery_OnClick () : Function has been called.");
      await CollectionsService.delete (this.$route.params.id);
      window.location.href = `/profile/`;
    },

    async btAddImage_OnClick () {
      const edImageName = document.getElementById ("edImageName") as HTMLInputElement;
      const edImageDescription = document.getElementById ("edImageDescription") as HTMLInputElement;
      const edImageLocation = document.getElementById ("edImageLocation") as HTMLInputElement;
      if (edImageName && edImageDescription && edImageLocation) {
        await ImagesService.add (
          edImageName.value,
          edImageDescription.value,
          edImageLocation.value,
          this.$route.params.id,
          []
        );
        await this.updateImages ();
      }
    },

    async selectSort_OnChange () {
      await this.showClientImages ();
    },

    showReceivedImages (inputImages: IImage []) {
      this.divImages = document.getElementById ("divImages");
      // console.log (`Images: ${JSON.stringify (inputImages)}, div : ${this.divImages}.`);
      if (this.divImages) {
            this.divImages.innerHTML = "";
        let i = 0;
        for (i = 0; i < inputImages.length; i ++) {
          this.showReceivedImage (inputImages [i]);
        }
      }
    },
    showReceivedImage (inputImage: IImage) {
      this.divImages.insertAdjacentHTML ("beforeend",
        // `<div class="MarginTopUltraSmall"><a style="display: flex; align-items: center;" href="/images/${inputImage.id}">
        //   ${inputImage.pathURL ? `<img style="width: 10em; margin-right: 0.5em;" src="${inputImage.pathURL}">` : ""}
        //   <span style="">${inputImage.name}</span>
        // </a></div>`
        `<a style="" href="/images/${inputImage.id}">
          ${inputImage.pathURL ? `<img style="" src="${inputImage.pathURL}">` : `<span style="">${inputImage.name}</span>`}
        </a>`
      );
    }
  }
})
export default class ComponentGallery extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .btWorkWithGallery {
    margin-left: 1em;
  }

  div label {
    display: inline-block;
    width: 16em;
  }

  #divImages a {
    display: inline-block;
  }

  #divImages span {
    margin-left: 1em;
  }

  /*#divSortControl select {
    background-color: #e0e0e0;
  }*/
</style>
