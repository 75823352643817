import axios from 'axios';

import {
	URL_DATA_PROCESSOR_IMAGES,
    DataProcessorPaths,
    EEntityStatus
} from '@/system/constants';

import {ClientsService} from '@/system/services/ClientsService';

export interface IImage {
	id: string;
    idOwner: string;
    name: string;
    description: string;
    location: string;
    path: string;
    pathURL: string;
    doesBelongToClient: boolean;
    rating: number;
}

export class ImagesService {
    static async add (
        inputName: string,
        inputDescription: string,
        inputLocation: string,
        inputGenreId: string,
        inputCollectionsIds: string []
        // inputStatus: number
    ): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.post(
                `${URL_DATA_PROCESSOR_IMAGES}`,
                {
                    "name": inputName,
                    "description": inputDescription,
                    "location": inputLocation,
                    "idGenre": inputGenreId,
                    "idsCollections": inputCollectionsIds
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.image
                ) {
                    console.error (`ImagesService.add () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['ImagesService.add () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ImagesService.add () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async getImages (
        inputGenreId: string | undefined,
        inputLimit: number = 24,
        inputAllImages: boolean = false,
        inputSort: string | undefined = undefined
    ): Promise <IImage [] | null> {
        let result = null;
        const promises = [] as Promise < void > [];
        const url = `${URL_DATA_PROCESSOR_IMAGES}?${inputAllImages ? "active=true" : `idGenre=${inputGenreId}`}&limit=${inputLimit}&sort=${inputSort}`;

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                url,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.images
                ) {
                    console.error (`ImagesService.getImages () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.images;
                resolve ();

            }).catch(function (error : any) {
                console.error(['ImagesService.getImages () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ImagesService.getImages () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async get (inputId: string): Promise <IImage | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_IMAGES}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                // console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.image
                ) {
                    console.error (`ImagesService.get () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.image;
                resolve ();

            }).catch(function (error : any) {
                console.error(['ImagesService.get () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ImagesService.get () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async update (
        inputId: string,
        inputName: string,
        inputDescription: string,
        inputLocation: string,
        inputCollectionsIds: string []
    ): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.put (
                `${URL_DATA_PROCESSOR_IMAGES}${inputId}/`,
                {
                    "name": inputName,
                    "description": inputDescription,
                    "location": inputLocation,
                    "idsCollections": inputCollectionsIds
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`ImagesService.update () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['ImagesService.update () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ImagesService.update () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async delete (inputId: string): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.delete (
                `${URL_DATA_PROCESSOR_IMAGES}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`ImagesService.delete () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['ImagesService.delete () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ImagesService.delete () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

     static async uploadImage (inputImageId: string, inputData: FormData) {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.post (
                DataProcessorPaths.getImageUploadingPath (inputImageId),
                inputData,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken (),
                        "Content-Type": "multipart/form-data"
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`ImagesService.uploadImage () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['ImagesService.uploadImage () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ImagesService.uploadImage () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
     }
}