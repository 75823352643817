import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/equipment_and_interiors',
    name: 'EqipmentAndInteriors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EqipmentAndInteriors.vue')
  },
  {
    path: '/rules',
    name: 'Rules',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
  },
  {
    path: '/events/:id',
    name: 'Event',
    component: () => import(/* webpackChunkName: "about" */ '../views/Event.vue')
  },
  // {
  //   path: '/about'
  // }
  {
    path: '/awards',
    name: 'Awards',
    component: () => import(/* webpackChunkName: "about" */ '../views/Awards.vue')
  },
  {
    path: '/journal',
    name: 'Journal',
    component: () => import(/* webpackChunkName: "about" */ '../views/Journal.vue')
  },
  {
    path: '/journal/:year',
    name: 'JournalIssue',
    component: () => import(/* webpackChunkName: "about" */ '../views/Journal.vue')
  },

  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/galleries/:id',
    name: 'Gallery',
    component: () => import('../views/Gallery.vue')
  },
  {
    path: '/images/',
    name: 'AllImages',
    component: () => import('../views/AllImages.vue')
  },
  {
    path: '/images/:id',
    name: 'Image',
    component: () => import('../views/Image.vue')
  },
  // {
  //   path: '/clients/',
  //   name: 'AllClients',
  //   component: () => import('../views/AllClients.vue')
  // },
  {
    path: '/clients/:id',
    name: 'Client',
    component: () => import('../views/Client.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
