
import { Options, Vue } from 'vue-class-component';

import {ClientsService} from '@/system/services/ClientsService';

import {
  ICollection,
  CollectionsService
} from '@/system/services/CollectionsService';

import {
  IImage,
  ImagesService
} from '@/system/services/ImagesService';

import {LocalStorage} from '@/system/common_code/Utilities';

@Options({
  props: {
    id: String,
    all: Boolean,
    limit: {
      type: Number,
      default: 24
    },
    sort: {
      type: String,
      default: ""
    },
    showCaption: Boolean,
    message: String
  },

  async created () {
    console.log (this.showCaption);

    if (this.all) {
      LocalStorage.setValue ("idGallery", "");

    } else {
      LocalStorage.setValue ("idGallery", this.$route.params.id);
    }

    await this.update ();
    await this.updateImages ();
  },

  methods: {
    async update () {
      if (this.all) {
        return;
      }

      const gallery = await CollectionsService.get (this.$route.params.id);
      console.log (gallery);
      const h2Caption = document.getElementById ("h2Caption");
      const edName = document.getElementById ("edName") as HTMLInputElement;
      if (gallery && h2Caption) {
        document.title = `Галерея ${gallery.name} - Фото-Фитнес-Студия ArtPro`;
        h2Caption.innerHTML = `Галерея ${gallery.name}`;
        edName.value = gallery.name;
      }
    },
    async updateImages () {
      const isLoggedIn = ClientsService.isLoggedIn ();

      // console.log (`Is client logged in is : ${isLoggedIn}.`);
      if (isLoggedIn || this.all) {
        await this.showClientImages ();
      }
    },
    async showClientImages () {
      const selectSort = document.getElementById ("selectSort") as HTMLInputElement;
      if (selectSort) {
        const sort = selectSort.value == "1" ? undefined : "rating";
        const images = await ImagesService.getImages (
          this.$route.params.id,
          this.limit,
          this.all,
          sort
        );
        if (images) {
          this.showReceivedImages (images);
        }
      }
    },

    btUpdateGallery_OnClick: async function () {
      const edName = document.getElementById ("edName") as HTMLInputElement;
      if (edName) {
        console.log ("Galleriy.btUpdateGallery_OnClick () : Function has been called.");
        await CollectionsService.update (this.$route.params.id, edName.value);
        // window.location.href = `/galleries/${this.$route.params.id}`;
        this.update ();
      }
    },
    btDeleteGallery_OnClick: async function () {
      console.log ("Galleriy.btDeleteGallery_OnClick () : Function has been called.");
      await CollectionsService.delete (this.$route.params.id);
      window.location.href = `/profile/`;
    },

    async btAddImage_OnClick () {
      const edImageName = document.getElementById ("edImageName") as HTMLInputElement;
      const edImageDescription = document.getElementById ("edImageDescription") as HTMLInputElement;
      const edImageLocation = document.getElementById ("edImageLocation") as HTMLInputElement;
      if (edImageName && edImageDescription && edImageLocation) {
        await ImagesService.add (
          edImageName.value,
          edImageDescription.value,
          edImageLocation.value,
          this.$route.params.id,
          []
        );
        await this.updateImages ();
      }
    },

    async selectSort_OnChange () {
      await this.showClientImages ();
    },

    showReceivedImages (inputImages: IImage []) {
      this.divImages = document.getElementById ("divImages");
      // console.log (`Images: ${JSON.stringify (inputImages)}, div : ${this.divImages}.`);
      if (this.divImages) {
            this.divImages.innerHTML = "";
        let i = 0;
        for (i = 0; i < inputImages.length; i ++) {
          this.showReceivedImage (inputImages [i]);
        }
      }
    },
    showReceivedImage (inputImage: IImage) {
      this.divImages.insertAdjacentHTML ("beforeend",
        // `<div class="MarginTopUltraSmall"><a style="display: flex; align-items: center;" href="/images/${inputImage.id}">
        //   ${inputImage.pathURL ? `<img style="width: 10em; margin-right: 0.5em;" src="${inputImage.pathURL}">` : ""}
        //   <span style="">${inputImage.name}</span>
        // </a></div>`
        `<a style="" href="/images/${inputImage.id}">
          ${inputImage.pathURL ? `<img style="" src="${inputImage.pathURL}">` : `<span style="">${inputImage.name}</span>`}
        </a>`
      );
    }
  }
})
export default class ComponentGallery extends Vue {
}
