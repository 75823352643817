
import { Options, Vue } from 'vue-class-component'
import ComponentGallery from '@/components/Gallery.vue'
import ComponentClients from '@/components/Clients.vue'

@Options({
  components: {
    ComponentGallery,
    ComponentClients
  },
  props: {
    msg: String
  },
  created () {
    document.title = "Главная страница - Фото-Фитнес-Студия ArtPro";
  },
})

export default class HelloWorld extends Vue {
  msg!: string
}
