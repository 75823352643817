<template>
	<header>
		<div class="container">
			<h1><a href="https://artpro.studio/"><img src="../assets/Logo_512_2.png" alt="ArtPro фото-фитнес-студия"></a></h1>

			<!--div id="nav">
				<router-link to="/">Home</router-link> |
				<router-link to="/about">About</router-link>
			</div-->

			<div id="divThemeSelectorHolder">
				<select id="selectTheme" v-on:change="selectTheme_OnSelect">
					<option value="light">Light</option>
					<option value="dark">Dark</option>
				</select>
			</div>

			<div id="divContactsHolder">
				<a href="https://vk.com/artpro.ekat" rel="nofollow"><img src="../assets/VK_Monochrome_2_Full_64.png" alt="ArtPro фото-фитнес-студия в ВК"></a>
				<!--a href="https://www.instagram.com/artpro.ekat/"><img src="../assets/Instagram_64.png" alt="ArtPro фото-фитнес-студия в Instagram"></a-->
				<!-- <a href="https://www.instagram.com/rodionkarimov1984/" rel="nofollow"><img src="../assets/Instagram_64.png" alt="ArtPro фото-фитнес-студия в Instagram"></a> -->
				<a href="https://t.me/ArtProPhotoStudio" rel="nofollow"><img src="../assets/telegram_PNG17_64.png" alt="ArtPro фото-фитнес-студия в Telegram"></a>
				<a href="https://t.me/RodionKarimov" rel="nofollow"><img src="../assets/telegram_PNG17_64.png" alt="ArtPro фото-фитнес-студия в Telegram"></a>
				<!-- <a href="https://wa.me/9221231624" rel="nofollow"><img src="../assets/whatsapp_64.png" alt="ArtPro фото-фитнес-студия в WhatsApp"></a> -->
				<!--a href="https://wa.me/9501924979" rel="nofollow"><img src="../assets/whatsapp_64.png" alt="ArtPro фото-фитнес-студия в WhatsApp"></a-->
			</div>
		</div>

		<div id="buttonAction">
			<a href="https://vk.me/artpro.ekat" title="Заказать студию или съёмки" rel="nofollow">Заказать!</a>
		</div>
	</header>

</template>



<style lang="scss">
#app header {
	position: fixed;
	left : 0;
	top : 0;
	width : 100%;
}

#app header .container {
	max-height : 6em;
}

#app header .container > * {
	vertical-align : middle;
	display : inline-block;
}

#app h1 {
	display : inline-block;
}

#app h1 img {
	height : 2em;
}

#app header #nav {
	padding: 30px;
	display : none;
}

#app header #divThemeSelectorHolder {
	position : absolute;
	right : 9em;
	bottom : 1.35em;
}

#app header #divContactsHolder {
	position : absolute;
	right : 1em;
	bottom : 0.95em;
}

#app header #divContactsHolder img {
	height : 2em;
	margin-top : 0.5em;
	margin-left : 0.5em;
}

/*#app header #divContactsHolder img:hover {
	height : 2.2em;
	margin-top : 0.3em;
	margin-left : 0.3em;
}*/

#app #buttonAction {
	position: fixed;
	right : 1em;
	top : 12em;
	padding : 0.5em 0.5em;

	border-radius : 0.5em;

	background-color : #663b51;

	writing-mode: vertical-rl;
	text-orientation: upright;
}

#app #buttonAction:hover {
	background-color : #7a455f;
}

#app #buttonAction:active {
	background-color : #4d2c3d;
}

#app #buttonAction a {
	color : #bbbbbb;
	text-decoration : none;
}



#app .dark header {
	color: #cf4e8f;
	background-color : #333333;
}

#app .light header {
	color: #cf4e8f;
	background-color : #333333;
}



@media screen and (max-width: 800px) {
	#app header #divContactsHolder {
		bottom : 0.5em;
		width : 6em;
	}

	#app header #divThemeSelectorHolder {
		right : 8em;
		bottom : 1.2em;
	}

	#app #buttonAction {
		right : 0em;
		top : 12em;
	}
}
</style>



<script>
	// import Vue from 'vue'
	import {Cookies} from '@/system/common_code/Utilities';

	// selectTheme = document.getElementById ( "selectTheme" );
	// function selectTheme_OnSelect () {
	// 	console.log ( selectTheme.options [ selectTheme.selectedIndex ].text );
	// };
	
	// function selectTheme_OnSelect ( event ) {
	// 	console.log(event.target.value);
	// }

	export default {
		mounted () {
			const theme = Cookies.getCookie ("theme");
			// console.log ( theme );
			if ( theme !== "" ) {
				document.getElementById ( "selectTheme" ).value = theme;
			}
			document.getElementById ("page").className = document.getElementById ("selectTheme").value.toLowerCase ();
		},
		methods : {
			selectTheme_OnSelect: function ( event ) {
				// `event` is the native DOM event
				// alert(event.target.tagName);
				// console.log ( event.target.value.toLowerCase () );
				document.getElementById ( "page" ).className = event.target.value.toLowerCase ();

				Cookies.setCookie ("theme", event.target.value.toLowerCase (), 30);
			}
		}
	};

</script>