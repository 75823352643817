import axios from 'axios';

import {
	URL_DATA_PROCESSOR_COLLECTIONS
} from '@/system/constants';

import {ClientsService} from '@/system/services/ClientsService';

export interface ICollection {
	id: string;
    name: string;
    description: string;
}

export class CollectionsService {
    static async add (inputName: string): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.post(
                `${URL_DATA_PROCESSOR_COLLECTIONS}`,
                {
                    "name": inputName,
                    "description": "Description"
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.collection
                ) {
                    console.error (`CollectionsService.add () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['CollectionsService.add () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CollectionsService.add () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async getCollections (): Promise <ICollection [] | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_COLLECTIONS}`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                // console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.collections
                ) {
                    console.error (`CollectionsService.getCollections () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.collections;
                resolve ();

            }).catch(function (error : any) {
                console.error(['CollectionsService.getCollections () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CollectionsService.getCollections () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async get (inputId: string): Promise <ICollection | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_COLLECTIONS}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                // console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.collection
                ) {
                    console.error (`CollectionsService.get () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.collection;
                resolve ();

            }).catch(function (error : any) {
                console.error(['CollectionsService.getCollections () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CollectionsService.getCollections () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async update (inputId: string, inputName: string): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.put (
                `${URL_DATA_PROCESSOR_COLLECTIONS}${inputId}/`,
                {
                    "name": inputName,
                    "description": "Description"
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`CollectionsService.update () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['CollectionsService.update () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CollectionsService.update () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async delete (inputId: string): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.delete (
                `${URL_DATA_PROCESSOR_COLLECTIONS}${inputId}/`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data) {
                    console.error (`CollectionsService.delete () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                resolve ();

            }).catch(function (error : any) {
                console.error(['CollectionsService.delete () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`CollectionsService.delete () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }
}