<template>
  <div class="hello" v-if="showCaption">
    <h2 id="h2Caption" class="MarginTopLarge">Подписчики</h2>
  </div>

  <p v-if="message">{{message}}</p>

  <div class="MarginTopMedium" id="divClients">
    
  </div>

  <div class="MarginTopMedium" id="divSortControl">
    Сортировать по : 
    <select id="selectSort" v-on:change="selectSort_OnChange">
      <option value="1">Дате</option>
      <option value="2">Рейтингу</option>
    </select>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import {IClient, ClientsService} from '@/system/services/ClientsService';

import {LocalStorage} from '@/system/common_code/Utilities';

@Options({
  props: {
    limit: {
      type: Number,
      default: 24
    },
    sort: {
      type: String,
      default: ""
    },
    showCaption: Boolean,
    message: String
  },

  async created () {
    // console.log (this.showCaption);

    await this.update ();
    await this.updateClients ();
  },

  methods: {
    async update () {
      
    },
    async updateClients () {
      await this.showClients ();
    },
    async showClients () {
      const selectSort = document.getElementById ("selectSort") as HTMLInputElement;
      if (selectSort) {
        const sort = selectSort.value == "1" ? undefined : "rating";
        const clients = await ClientsService.getClients (
          this.limit,
          sort
        );
        if (clients) {
          this.showReceivedClients (clients);
        }
      }
    },

    async selectSort_OnChange () {
      await this.showClients ();
    },

    showReceivedClients (inputClients: IClient []) {
      this.divClients = document.getElementById ("divClients");
      // console.log (`Clients: ${JSON.stringify (inputClients)}, div : ${this.divClients}.`);
      if (this.divClients) {
            this.divClients.innerHTML = "";
        let i = 0;
        for (i = 0; i < inputClients.length; i ++) {
          this.showReceivedClient (inputClients [i]);
        }
      }
    },
    showReceivedClient (inputClient: IClient) {
      this.divClients.insertAdjacentHTML ("beforeend",
        `<a style="" href="/clients/${inputClient.id}">
          ${inputClient.name} ${inputClient.surname}
        </a> - ${inputClient.rating}`
      );
    }
  }
})
export default class ComponentClients extends Vue {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .btWorkWithGallery {
    margin-left: 1em;
  }

  div label {
    display: inline-block;
    width: 16em;
  }

  #divClients a {
    display: inline-block;
  }

  #divClients span {
    margin-left: 1em;
  }

  /*#divSortControl select {
    background-color: #e0e0e0;
  }*/
</style>
