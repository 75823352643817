import axios from 'axios';

import {
	URL_DATA_PROCESSOR_CLIENTS_LOGIN_YANDEX,
    URL_DATA_PROCESSOR_CLIENTS_LOGIN_VK,
    URL_DATA_PROCESSOR_CLIENTS_ADD_VK_ID,
    URL_DATA_PROCESSOR_CLIENTS_ADD_YANDEX_ID,
	DAYS_TOKEN_REMEMBERING,
    URL_DATA_PROCESSOR_CLIENTS,
	URL_DATA_PROCESSOR_CLIENTS_US,
    URL_DATA_PROCESSOR_CLIENTS_OUR_ACCOUNT,
    EEntityStatus
} from '@/system/constants';
import {LocalStorage, SessionStorage} from '@/system/common_code/Utilities';

export interface IContacts {
    emailPublic ? : string;
    phonePublic ? : string;
    site ? : string;
    urlVK ? : string;
    urlTelegramm ? : string;
}

export interface IClient {
	id: string;
    name: string;
    surname : string;
    dateBirth: Date;
    gender:  number;
    email: string;
    idPhoto: string;
    ids: object;
    country ? : string;
    city ? : string;
    biography ? : string;
    contacts ? : IContacts;
    limitUploadCurrent: number;
    maxImagesAmount: number;
    amountImages: number;
    typeAccount: number;
    rating: number;
    typeClient: number;
    status: number;
    dateCreation: Date;
}

export interface IOtherClient {
    id: string;
    name: string;
    surname : string;
    dateBirth: Date;
    gender:  number;
    idPhoto: string;
    ids: object;
    country ? : string;
    city ? : string;
    biography ? : string;
    contacts ? : IContacts;
    amountImages: number;
    status: number;
    dateCreation: Date;
}

export const EGender = {
	Man : 1,
	Woman : 2
}

export const g_namesGenders = ["Мужчина", "Женщина"];
const g_namesStatuses = ["Активный", "Заморожен", "Заблокирован"];

export class ClientsService {
    static async loginYandex (inputToken: string): Promise <boolean> {
        const response = await axios.get(
        	`${URL_DATA_PROCESSOR_CLIENTS_LOGIN_YANDEX}?token=${inputToken}`
        );
        // console.log (response.data.data.client.tokenAuthentication);
        if (response.data.data.client.tokenAuthentication) {
        	// Cookies.setCookie (
        	// 	"tokenAccess",
        	// 	response.data.data.client.tokenAuthentication,
        	// 	DAYS_TOKEN_REMEMBERING
        	// );
        	LocalStorage.setValue ("tokenAccess", response.data.data.client.tokenAuthentication);
        	return true;
        }

        return false;
    }

    static async loginVK (inputCode: string): Promise <boolean> {
        const response = await axios.get(
            `${URL_DATA_PROCESSOR_CLIENTS_LOGIN_VK}?code=${inputCode}`
        );
        // console.log (response.data.data.client.tokenAuthentication);
        if (response.data.data.client.tokenAuthentication) {
            LocalStorage.setValue ("tokenAccess", response.data.data.client.tokenAuthentication);
            return true;
        }

        return false;
    }

    static async addVKId (inputCode: string): Promise <void> {
        const response = await axios.get(
            `${URL_DATA_PROCESSOR_CLIENTS_ADD_VK_ID}?code=${inputCode}`
        );
    }

    static async addYandexId (inputToken: string): Promise <void> {
        const response = await axios.get(
            `${URL_DATA_PROCESSOR_CLIENTS_ADD_YANDEX_ID}?token=${inputToken}`
        );
    }

    static isLoggedIn (): boolean {
    	// console.log (ClientsService.getAuthorizationToken ());
    	// return ClientsService.getAuthorizationToken () != "";
    	return !!ClientsService.getAuthorizationToken ();
    }

    static getAuthorizationToken (): string | null {
    	// return Cookies.getCookie ("tokenAccess");
    	return LocalStorage.getValue ("tokenAccess");
    }

    static logout () {
    	// Cookies.setCookie ("tokenAccess", "", -50);
    	LocalStorage.deleteValue ("tokenAccess");
    }

    static setRememberOnComputer (inputRemember: boolean): void {
    	if (inputRemember) {
	    	SessionStorage.setValue ("rememberOnComputer", "Remember");

    	} else {
			SessionStorage.deleteValue ("rememberOnComputer");
    	}
    }

    static getRememberOnComputer (): boolean {
    	return !!SessionStorage.getValue ("rememberOnComputer");
    }

    static async getClients (
        inputLimit: number = 24,
        inputSort: string | undefined = undefined
    ): Promise <IClient [] | null> {
        let result = null;
        const promises = [] as Promise < void > [];
        const url = `${URL_DATA_PROCESSOR_CLIENTS}?limit=${inputLimit}&sort=${inputSort}`;

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                url,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                console.log (response.data);
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.clients
                ) {
                    console.error (`ClientsService.getClients () : ${JSON.stringify (response.data)} .`);
                    resolve ();
                }
                result = response.data.data.clients;
                resolve ();

            }).catch(function (error : any) {
                console.error(['ClientsService.getClients () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ClientsService.getClients () : ${JSON.stringify (error.response.data)} .`);
                }
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async GetClient (inputClientId: string): Promise <IOtherClient | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_CLIENTS}${inputClientId}`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.client
                ) {
                    console.error (`ClientsService.GetClient () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.client;
                resolve ();

            }).catch(function (error : any) {
                console.error(['ClientsService.GetClient () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ClientsService.GetClient () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async GetUs (): Promise <IClient | null> {
        let result = null;
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.get(
                `${URL_DATA_PROCESSOR_CLIENTS_US}`,
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                if (!response || !response.data ||
                    !response.data.data || !response.data.data.client
                ) {
                    console.error (`ClientsService.GetUs () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                result = response.data.data.client;
                resolve ();

            }).catch(function (error : any) {
                console.error(['ClientsService.GetUs () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ClientsService.GetUs () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);

        return result;
    }

    static async UpdateOurAccount (
        inputCountry: string | undefined,
        inputCity: string | undefined,
        inputBiography: string | undefined,
        inputEMailPublic: string | undefined,
        inputPhonePublic: string | undefined,
        inputSite: string | undefined,
        inputURLVK: string | undefined,
        inputURLTelegramm: string | undefined
    ): Promise <void> {
        const promises = [] as Promise < void > [];

        promises.push (new Promise (async(resolve: Function, reject: Function) => {
            axios.put(
                `${URL_DATA_PROCESSOR_CLIENTS_US}`,
                {
                    country: inputCountry,
                    city: inputCity,
                    biography: inputBiography,
                    contacts: {
                        emailPublic: inputEMailPublic,
                        phonePublic: inputPhonePublic,
                        site: inputSite,
                        urlVK: inputURLVK,
                        urlTelegramm: inputURLTelegramm
                    }
                },
                { 
                    headers: {
                        "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                    }
                }

            ).then(async (response : any) => {
                if (!response || !response.data) {
                    console.error (`ClientsService.UpdateOurAccount () : ${JSON.stringify (response.data)} .`);
                    // reject ();
                    resolve ();
                }
                console.log (response.data);
                resolve ();

            }).catch(function (error : any) {
                console.error(['ClientsService.UpdateOurAccount () : axios.get () error : ', error, '.'].join (''));
                if (error && error.response) {
                    console.error (`ClientsService.UpdateOurAccount () : ${JSON.stringify (error.response.data)} .`);
                }
                // reject ();
                resolve ();
            });
        }) );

        await Promise.all (promises);
    }

    static async DeleteOurAccount (): Promise <void> {
        const response = await axios.delete(
            `${URL_DATA_PROCESSOR_CLIENTS_OUR_ACCOUNT}`,
            { 
                headers: {
                    "Authorization" : "Bearer " + ClientsService.getAuthorizationToken ()
                }
            }
        );
        console.log (response.data);
    }

    static getGenderName (inputGender : number): string {
    	if (inputGender < 1 || inputGender > g_namesGenders.length) {
    		return "";
    	}

    	return g_namesGenders [inputGender - 1];
    }

    static getStatusName (inputStatus: number): string {
        if (inputStatus < 1 || inputStatus > g_namesStatuses.length) {
            return "";
        }

        return g_namesStatuses [inputStatus - 1];
    }
}