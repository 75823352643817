<template>
  <div class="hello">
    <h2>{{ msg }}</h2>

    <p>Фото и видео - съёмки в Екатеринбурге, Москве, Санкт-Петербурге и Сочи и фото-студия. Выполняем :</p>

    <ul>
      <li>Профессиональные Портретные, Фитнес, Fashion и Арт фото съёмки.</li>
      <li>Съёмки каталогов и коллекций одежды, обуви, украшений, аксессуаров и мобильных устройств интернет-магазинов, производителей и дизайнеров.</li>
      <li>Творческие рекламные съёмки для компаний, брендов и частных клиентов : продвижение бренда, товары, услуги, локации и другие рекламируемые объекты.</li>
      <li>Съёмки визуалов для частных клиентов и брендов.</li>

      <li>У нас в студии можем организовать съёмки видео, профессиональную запись аудио и онлайн-вещания, в том числе с применением трёхмерной графики : добавление трёхмерных объектов в реальные сцены или добавление реальных объектов, снятых на Chroma-key'е, в трёхмерные сцены с помощью движка Unreal Engine 4.</li>
    </ul>

<p>Стоимость съёмок от 3-х т.р. за час фотографирования, в зависимости от задач.</p>

<p>У нас есть мобильный профессиональный свет - можем организовать фотосессии в резиденциях, отелях, библиотеках, музеях и других интересных местах. И профессиональные свет, модификаторы, фото-зоны и фоны для организации всех видов съёмок.</p>

<p>Предоставляем студию в аренду для фото и видео - съёмок, фитнес-тренировок и мастер-классов.</p>

<p>Площадь студии 40,5 квадратных метров, высота потолков 3 м.</p>

<p>700 рублей в пятницу - воскресенье и праздники и 600 рублей в понедельник - четверг.
Вы можете приобрести у нас сертификат на 10 часов аренды студии за 5800 р.</p>

<p>По вопросам аренды студии и заказа съёмок пишите в <a href="https://vk.me/artpro.ekat" rel="nofollow">ЛС группы</a> .</p>

<p>ИП Каримов Р.М. - ИНН 667113824831 , ОГРНИП 319665800203980.</p>

<p>Город Екатеринбург, ул. Красноармейская, 10, БЦ "Антей", 8-й этаж, 812-й офис.</p>

<p><a href="/rules">Правила</a> работы в студии ArtPro.</p>



    <!--p>Профессиональные Портретные, Фитнес, Fashion и Арт фото съёмки,<br/>съёмки каталогов одежды и аксессуаров интернет-магазинов и дизайнеров,<br/>рекламные съёмки для компаний, брендов и частных клиентов,<br/>съёмки визуалов для частных клиентов и брендов в <b>Москве</b>, <b>Санкт-Петербурге</b>, <b>Екатеринбурге</b> и <b>Сочи</b>.<br/>Также фотографируем архитектуру и интерьеры.<br/>
    У нас есть мобильный профессиональный свет - можем организовать фотосессии в резиденциях, отелях, библиотеках, музеях и других интересных местах.<br/>
    У нас в студии можем организовать съёмки видео, профессиональную запись аудио и онлайн-вещания, в том числе с применением трёхмерной графики : добавление трёхмерных объектов в реальные сцены или добавление реальных объектов, снятых на Chroma-key'е, в трёхмерные сцены с помощью движка <b>Unreal Engine 4</b>.<br/>
    Снимаем также на улице в городах в интересное время при подходящей погоде, если есть интересные идеи и для коммерческих клиентов.<br/>
    Стоимость съёмок от 3-х т.р. за час фотографирования, в зависимости от задач.</p-->

<!--p>Создаём интерактивные трёхмерные визуализации с применением трэкинга людей с помощью камер глубины, например, <b>Intel Real Sense</b> и движка UE 4.</p>

<p>Выполняем работы по созданию дизайнов сайтов-визиток и их самих с применением <b>Vue.js</b> и по созданию <b>Node.JS</b> <b>API</b>-приложений.</p-->

<!--p>Предоставляем студию в аренду для фото и видео - съёмок, фитнес-тренировок и мастер-классов.</p>

<p>Арендуйте студию у нас! - у нас есть профессиональный свет; фоны для каталожных, модельных, предметных и творческих съёмок; оборудование, выход на крышу, а также дополнительные аксессуары и инвентарь и возможности для реализации творческих и технических задач по привлекательным ценам в Екатеринбурге. Студия расположена в центре города : Красноармейская, 10 - БЦ "Антей", 812 офис, 8-й этаж.</p>

<p>Площадь студии 40,5 квадратных метров, высота потолков 3 м.</p>

<p>700 рублей в пятницу - воскресенье и праздники и 600 рублей в понедельник - четверг.<br/>
Вы можете приобрести у нас сертификат на 10 часов аренды студии за 5800 р.</p>

<p>По вопросам аренды студии и заказа съёмок пишите в <a href="https://vk.me/artpro.ekat" rel="nofollow">ЛС группы</a> .</p>

<p>Ищем интернет-магазины и дизайнеров одежды и аксессуаров для съёмок каталогов товаров.<br/>
Ищем дополнительных стилистов и визажистов для организации совместных коммерческих проектов у нас в студии.<br/>
Открыты к взаимовыгодному сотрудничеству с производителями мебели и брендами Модной и Фитнес - одежды.</p>

<p>ИП Каримов Р.М. - ИНН 667113824831 , ОГРНИП 319665800203980.</p>

<p>Город Екатеринбург, ул. Красноармейская, 10, БЦ "Антей", 8-й этаж, 812-й офис.</p>

<p><a href="/rules">Правила</a> работы в студии ArtPro.</p-->
    <!--p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router" target="_blank" rel="noopener">router</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-vuex" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-typescript" target="_blank" rel="noopener">typescript</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul-->
  </div>

  <h3>Рейтинги изображений</h3>
  <div class="MarginTopSmall"><router-link to="/images/">Перейти к рейтингам</router-link></div>
  <ComponentGallery :all="true" :showCaption="false" :limit="8" sort="rating"/>
  <h3>Подписчики</h3>
  <ComponentClients :showCaption="false" :limit="10"/>

  <h3>Фотографии студии</h3>
  <div class="row">
  <div class="column">
    <a href="/resources/images/photos/portfolio/_DSC1573_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC1573_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC5653_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC5653_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.6.27/_DSC8194_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC8194_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.3.11/_DSC3299_1080.jpg"><img src="/resources/images/photos/portfolio/22.3.11/_DSC3299_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/21.8.3/_DSC5261.jpg"><img src="/resources/images/photos/portfolio/21.8.3/_DSC5261.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC1646_2_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC1646_2_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/21.6.27/_DSC5188_2_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC5188_2_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/2/_DSC3797_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC3797_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/21.6.27/_DSC4441_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC4441_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC5277_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC5277_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.3.11/_DSC9599_2_1080.jpg"><img src="/resources/images/photos/portfolio/22.3.11/_DSC9599_2_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/21.6.27/_DSC6944_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC6944_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/2/_DSC3163_2_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC3163_2_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.10.24/_DSC8738.jpg"><img src="/resources/images/photos/portfolio/21.10.24/_DSC8738.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.3.11/_DSC9801_1080.jpg"><img src="/resources/images/photos/portfolio/22.3.11/_DSC9801_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/22.3.11/_DSC4998_1080.jpg"><img src="/resources/images/photos/portfolio/22.3.11/_DSC4998_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/2/_DSC2368_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC2368_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/2/_DSC4638_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC4638_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/2/_DSC3763_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC3763_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/2/_DSC3489_2_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC3489_2_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.4.8/_DSC9595.jpg"><img src="/resources/images/photos/portfolio/21.4.8/_DSC9595.jpg"></a-->
  </div>
  <div class="column">
    <a href="/resources/images/photos/portfolio/22.6.22/_DSC3294_1080.jpg"><img src="/resources/images/photos/portfolio/22.6.22/_DSC3294_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/_DSC4004_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC4004_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC1727_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC1727_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/21.6.27/_DSC3662_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC3662_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/21.10.24/_DSC8394.jpg"><img src="/resources/images/photos/portfolio/21.10.24/_DSC8394.jpg"></a-->
    <a href="/resources/images/photos/portfolio/23.3.12/_DSC1230_2_1080.jpg"><img src="/resources/images/photos/portfolio/23.3.12/_DSC1230_2_1080.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/21.8.3/_DSC0719.jpg"><img src="/resources/images/photos/portfolio/21.8.3/_DSC0719.jpg"></a-->
    <a href="/resources/images/photos/portfolio/21.6.27/_DSC3481_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC3481_1280.jpg"></a>
    <a href="/resources/images/photos/portfolio/_DSC4624_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC4624_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC1820_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC1820_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/_DSC1854_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC1854_1280.jpg"></a>
    <a href="/resources/images/photos/portfolio/21.4.8/_DSC0217.jpg"><img src="/resources/images/photos/portfolio/21.4.8/_DSC0217.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC1854_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC1854_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.4.8/_DSC0330.jpg"><img src="/resources/images/photos/portfolio/21.4.8/_DSC0330.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.8.3/_DSC0507.jpg"><img src="/resources/images/photos/portfolio/21.8.3/_DSC0507.jpg"></a-->
    <a href="/resources/images/photos/portfolio/23.3.12/_DSC0996_1080.jpg"><img src="/resources/images/photos/portfolio/23.3.12/_DSC0996_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/_DSC4556_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC4556_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC4149_2_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC4149_2_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/_DSC1796_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC1796_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/_DSC4170_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC4170_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/2/_DSC9921_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC9921_1280.jpg"></a>
  </div>
  <div class="column">
    <a href="/resources/images/photos/portfolio/_DSC9310_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC9310_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/21.6.27/_DSC2187_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC2187_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.10.14/_DSC2479_1080.jpg"><img src="/resources/images/photos/portfolio/22.10.14/_DSC2479_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/22.6.22/_DSC3597_1080.jpg"><img src="/resources/images/photos/portfolio/22.6.22/_DSC3597_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/21.4.8/_DSC0732.jpg"><img src="/resources/images/photos/portfolio/21.4.8/_DSC0732.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC9306_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC9306_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.6.27/_DSC3112_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC3112_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/21.8.3/_DSC5025_2.jpg"><img src="/resources/images/photos/portfolio/21.8.3/_DSC5025_2.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/21.6.27/_DSC1915_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC1915_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/23.3.12/_DSC9448_1080.jpg"><img src="/resources/images/photos/portfolio/23.3.12/_DSC9448_1080.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC8801_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC8801_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.6.27/_DSC5773_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC5773_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.10.14/_DSC3482_1080.jpg"><img src="/resources/images/photos/portfolio/22.10.14/_DSC3482_1080.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/22.6.22/_DSC2825_1080.jpg"><img src="/resources/images/photos/portfolio/22.6.22/_DSC2825_1080.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.10.14/_DSC5980_1080.jpg"><img src="/resources/images/photos/portfolio/22.10.14/_DSC5980_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/_DSC9468_2_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC9468_2_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/_DSC8764_1280.jpg"><img src="/resources/images/photos/portfolio/_DSC8764_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/21.6.27/_DSC5577_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC5577_1280.jpg"></a>
  </div>
  <div class="column">
    <a href="/resources/images/photos/portfolio/2/_DSC5618_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC5618_1280.jpg"></a>
    <a href="/resources/images/photos/portfolio/21.4.8/_DSC0999_2.jpg"><img src="/resources/images/photos/portfolio/21.4.8/_DSC0999_2.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/2/_DSC0143_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC0143_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.3.11/_DSC1548_1080.jpg"><img src="/resources/images/photos/portfolio/22.3.11/_DSC1548_1080.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/2/_DSC5990_2_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC5990_2_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/2/_DSC0223_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC0223_1280.jpg"></a-->
    <a href="/resources/images/photos/portfolio/21.10.24/_DSC9334_2.jpg"><img src="/resources/images/photos/portfolio/21.10.24/_DSC9334_2.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/21.6.27/_DSC6215_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC6215_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.10.24/_DSC9914.jpg"><img src="/resources/images/photos/portfolio/21.10.24/_DSC9914.jpg"></a-->
    <a href="/resources/images/photos/portfolio/23.3.12/_DSC8229_1080.jpg"><img src="/resources/images/photos/portfolio/23.3.12/_DSC8229_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/22.6.22/_DSC3827_1080.jpg"><img src="/resources/images/photos/portfolio/22.6.22/_DSC3827_1080.jpg"></a>
    <a href="/resources/images/photos/portfolio/2/_DSC0289_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC0289_1280.jpg"></a>
    <a href="/resources/images/photos/portfolio/21.4.8/_DSC3019.jpg"><img src="/resources/images/photos/portfolio/21.4.8/_DSC3019.jpg"></a>
    <a href="/resources/images/photos/portfolio/2/_DSC0228_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC0228_1280.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/2/_DSC5619_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC5619_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/21.4.8/_DSC2832_2.jpg"><img src="/resources/images/photos/portfolio/21.4.8/_DSC2832_2.jpg"></a-->
    <a href="/resources/images/photos/portfolio/22.3.11/_DSC6887_2_1080.jpg"><img src="/resources/images/photos/portfolio/22.3.11/_DSC6887_2_1080.jpg"></a>
    <!--a href="/resources/images/photos/portfolio/21.6.27/_DSC1049_1280.jpg"><img src="/resources/images/photos/portfolio/21.6.27/_DSC1049_1280.jpg"></a-->
    <!--a href="/resources/images/photos/portfolio/2/_DSC0274_1280.jpg"><img src="/resources/images/photos/portfolio/2/_DSC0274_1280.jpg"></a-->
  </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ComponentGallery from '@/components/Gallery.vue'
import ComponentClients from '@/components/Clients.vue'

@Options({
  components: {
    ComponentGallery,
    ComponentClients
  },
  props: {
    msg: String
  },
  created () {
    document.title = "Главная страница - Фото-Фитнес-Студия ArtPro";
  },
})

export default class HelloWorld extends Vue {
  msg!: string
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  /*list-style-type: none;*/
  padding: 0;
}
li {
  /*display: inline-block;*/
  margin: 0 10px;
}
</style>
