<template>
	<footer>
		<div class="container">
			<div id="nav">
				<router-link to="/">Начало</router-link> |
				<!--router-link to="/about">About</router-link-->
				<!--router-link to="/equipment_and_interiors">Оборудование и интерьеры</router-link> |-->
				<router-link to="/rules">Правила</router-link> |
				<!--router-link to="/events">События</router-link> |-->
				<router-link to="/journal">Журнал</router-link> |
				<router-link to="/awards">Награды</router-link> |
				<router-link to="/images/">Изображения</router-link> |
				<span id="linkLogin"><router-link to="/profile">Логин</router-link></span>
				<span id="linkProfile"><router-link to="/profile">Профиль</router-link></span>
			</div>
		</div>
	</footer>

</template> 



<style lang="scss">
#app footer {
	position: fixed;
	left : 0;
	bottom : 0;
	width : 100%;
}

#app footer .container {
	vertical-align : middle;
}

#app footer .container > * {
	vertical-align : middle;
}

#app footer #nav {
	display : inline-block;
	padding : 5px;
}



#app .dark footer {
	color: #cf4e8f;
	background-color : #444444;
}



#app .light footer {
	color: #cf4e8f;
	background-color : #444444;
}
</style>



<script>
	import {ClientsService} from '@/system/services/ClientsService';

	export default {
		mounted () {
			const isLoggedIn = ClientsService.isLoggedIn ();
			document.getElementById ("linkLogin").style.display = isLoggedIn ? "none" : "inline";
			document.getElementById ("linkProfile").style.display = isLoggedIn ? "inline" : "none";
		},
		methods : {
		}
	};

</script>
