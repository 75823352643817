export interface IEventInList {
    idHumanReadable: string;
    title : string;
    p1 : string;
    p2 : string;
    images1 : string [],
	images2 : string []
}

export class Utilities {
    static htmlToElements ( inputHTML : string ) : Node {
        const template = document.createElement ( 'template' );
        template.innerHTML = inputHTML;
        return template.content.firstChild as Node;
    }
}

export class Cookies {
    static setCookie (cname: string, cvalue: string, exdays: number): void {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static getCookie (cname: string): string {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}

export class LocalStorage {
    static setValue (cname: string, cvalue: string): void {
        localStorage.setItem (cname, cvalue);
    }

    static getValue (cname: string): string | null {
        return localStorage.getItem (cname);
    }

    static deleteValue (cname: string): void {
        return localStorage.removeItem (cname);
    }
}

export class SessionStorage {
    static setValue (cname: string, cvalue: string): void {
        sessionStorage.setItem (cname, cvalue);
    }

    static getValue (cname: string): string | null {
        return sessionStorage.getItem (cname);
    }

    static deleteValue (cname: string): void {
        return sessionStorage.removeItem (cname);
    }
}
